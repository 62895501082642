//Fonts
@import url('https://fonts.googleapis.com/css?family=Bree+Serif') 

//Variáveis
$primary = #A793FE
$second = #E97EC6
$red = #ff0000
$white = #ffffff
$black = #242223

.video-rrsoares
  width 100% !important

header
  border-top 3px solid $primary
  //background linear-gradient(45deg,rgba(131,135,255,.8) 0,rgba(198,100,250,.8) 50%,rgba(233,24,133,.5) 100%)
  //background-image radial-gradient(circle, #f1c7ff, #f0d6ff, #f2e5ff, #f7f2ff, #ffffff)
  // background #f5f5f5
  background #fff
  //height 100vh
  transition-property min-height
  transition-duration 1s
  //min-height 680px
  #menu-top
    padding 1rem 0
    //position absolute
    top 0
    left 0
    z-index 20
    width 100%
    font-size 13px
    nav
      a
        // color $white !important
        margin 0 auto
        img
          height 108px
        &.nav-link
          text-transform uppercase
          color $black
          font-weight bold !important
          font-weight 100
          &:hover
            color $second
      .navbar-toggler
        border 1px solid $second !important
        .navbar-toggler-icon
           background-image url("data:image/svg+xml; charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(204,102,204, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important 
    @media screen and (max-width 991.98px)
      .navbar-collapse
        &.show
          display block
          //margin-top -35px !important 
        .navbar-nav
          display block 
          float right 
          li
            text-align center
            background #fff 
            display block 
            padding 0 1rem 
            a
              text-align center
              @media screen and (max-width: 767px)
                text-align right !important          
  #call-to-action
    height 100%
    width 100%
    position relative
    top 0
    z-index 1
    .container
      height 100%
      .row
        height 100%
        .col-md-8
          height 100%
        .cta-center
          display flex
          align-items center
          height 100%
          .banner-center-box
            padding 150px 0 100px
            max-height 100%
            max-width 100%
            margin 0 auto
            h2
              font-family 'Bree Serif', serif 
              font-size 2.5rem
              @media screen and (max-width 991.98px)
                font-size 2rem
              @media screen and (max-width 500.98px)
                font-size 2rem
              span
                display block
            a
              cursor pointer
              display inline-block
              text-transform uppercase
              font-weight 600
              height 50px
              line-height 51px
              padding 0 26px
              font-size 12px
              position relative
              color #fff
              background #e97ec6
              transition all 0.2s
              width auto
              border-radius 4px
              border none
              letter-spacing 2px
              margin 1rem 11px 0
  .wave-bg
    display block
    //display none
    content ''
    height 100%
    background url(../images/waves.svg) bottom no-repeat
    background-size contain
    position absolute
    bottom -1px
    left -1px
    right -1px
    z-index 1
    width 100%

///////////////////////////////////
////////// FINAL HEAD
///////////////////////////////////

///////////////////////////////////
////////// FORM SIGNUP
///////////////////////////////////

#social
  //background-color $second
  // background-image radial-gradient(circle, #f1c7ff, #f0d6ff, #f2e5ff, #f7f2ff, #ffffff) 
  padding 2rem 0 0
  background-color $second + 80
  ul
    list-style-type none
    padding 0
    margin 0
    li
      list-style-type none
      display inline-block
      &.facebook
        a
          background-color #3b5998
      &.twitter
        a
          background-color #55acee
      &.youtube
        a
          background-color #df4b38
      &.google
        a
          background-color #cc181e
      &.whatsapp
        a
          background-color #43c62d
      a
        width 50px
        display block
        text-align center
        padding 0.5rem 0
        border-radius 3px
        color $white

///////////////////////////////////
////////// FINAL SHARE
///////////////////////////////////

#o-projeto
  background-color $white
  padding 3rem 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  a
    cursor pointer
    display inline-block
    text-transform uppercase
    font-weight 600
    height 50px
    line-height 51px
    padding 0 26px
    font-size 12px
    position relative
    border none
    color #fff
    background #e97ec6
    transition all 0.2s
    width auto
    border-radius 4px
    letter-spacing 2px
    margin 1rem auto 0

#numeros
  background-color $primary + 100
  padding 3rem 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  .count
    margin-top 2rem
    i
      font-size 5rem
    p
      text-transform uppercase
      strong
        display block
        font-size 2rem

#faq
  background-color $white - 10
  padding 3rem 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  .accordion
    .card
      margin-bottom 1rem
      border none
      .card-header:first-child
        background-color $white + 80
        border-bottom  2px solid $primary + 70
        h5
          button
            color $black
            // text-transform uppercase
            font-weight bold
            font-style italic
            white-space normal
            width 100%

#onde-encontrar
  background-color $white
  padding 3rem 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  .form-inline
    margin 0 auto
    button
      background #e97ec6
      border-color #e97ec6

#home-artigos
  background-color $white - 5
  padding 3rem 0 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  a
    text-decoration none
    h4
      color $black
      // font-family 'Bree Serif', serif !important
      // text-transform uppercase
      margin 1rem 0
  .col-md-4
    img
      width 100%
      height auto
  .home-artigos-duplo
    a
      transition: all .25s;
      figure
        position relative
        padding 0 1rem 1rem 1rem
        img
          width 100%
          height auto
        figcaption
          // position absolute
          bottom 0
          width 100%
          padding 1rem 0
          background rgba(255,255,255,0.3)
          transition all .25s
          h4
            color $black
            // font-family 'Bree Serif', serif !important
            // text-transform uppercase
            margin -5px 0rem 0.5rem 0
          p
            color $black
            margin 0
      &:hover
        figure
          figcaption
            background rgba(255,255,255,0.8)
  .home-artigos-triplo
    margin 0rem 0
    .col-md-4
      @media screen and (max-width: 767px)
        margin-bottom: 2rem

#contato
  background-color $white
  padding 3rem 0
  h3
    &::after
      border-top 2px solid $second
      content ''
      width 80px
      display block
      margin 1rem auto 2rem
  form
    margin 0 auto
    label
      font-weight bold
    .invalid-feedback
      color #e97ec6
    button
      background #e97ec6
      border-color #e97ec6

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: $second !important;
}

h3
  // font-family 'Bree Serif', serif !important
  // text-transform uppercase
  // margin-bottom 1rem

.modal
  button
    background #e97ec6
    border-color #e97ec6
    &:hover
      background #ca5da6
      border-color #ca5da6

///////////////////////////////////
////////// FOOTER
///////////////////////////////////

footer
  background-color $second + 80
  padding 2rem 0
  font-weight 500
  color $black
  // text-transform uppercase
  font-size 0.9rem

///////////////////////////////////
////////// FINAL FOOTER
///////////////////////////////////

.down
  width 100%
  position absolute
  bottom 0
  text-align center
  i
    color $white
    border-radius 20px 20px 0 0
    padding 1rem
    background $primary + 30
    box-shadow 0px -6px 20px rgba(0,0,0,0.1)

// header 
//   img
    // @media screen and (max-height 720px)
    //   height 100px !important

#return-to-top
  position fixed
  bottom 20px
  right 20px
  background $second
  width 50px
  height 50px
  display block
  text-decoration none
  -webkit-border-radius 35px
  -moz-border-radius 35px
  border-radius 35px
  display none
  -webkit-transition all 0.3s linear
  -moz-transition all 0.3s ease
  -ms-transition all 0.3s ease
  -o-transition all 0.3s ease
  transition all 0.3s ease
  i
    color #fff
    margin 0
    position relative
    left 16px
    top 13px
    font-size 19px
    -webkit-transition all 0.3s ease
    -moz-transition all 0.3s ease
    -ms-transition all 0.3s ease
    -o-transition all 0.3s ease
    transition all 0.3s ease
  &:hover
    background $second + 10
    i
      color #fff
      top 5px

#artigos
  .block-audio
    border 1px solid lighten($black, 70)
    border-radius: 0.5rem
    padding 1rem
    &:hover
      box-shadow 0 0 30px -15px
  header
    height auto
    min-height auto
    #menu-top
      position relative
  .title
    margin 2rem 0
    h2
      border-left 3px solid $primary
      padding-left 1rem
      // font-family 'Bree Serif', serif !important
      // text-transform uppercase
      margin-bottom 1rem
      color $second
  .artigos-listagem, .galeria-listagem
    .row
      min-height 285px
      .col-md-8
      .col-md-12
        margin-bottom 1rem
        h3
          a
            color $black
            text-decoration none
        span
          // margin-bottom: 0.8rem;
          display: block;
          a
            background-color $white - 5
            color $white - 80
            display inline-block
            padding 0.2rem 0.4rem
            font-weight bold
            font-size 0.6rem
            border-radius 5px
            text-decoration none
      .link
        background-color $second
        padding 1rem
        color $white
        display inline-block
        padding 0.5rem 1rem
        font-weight bold
        font-size 0.7rem
        border-radius 5px
        text-decoration none
    .image
      img
        width 100%
        height auto
  aside
    .artigos-categorias
      h4
        // font-family 'Bree Serif', serif !important
        // text-transform uppercase
        margin-bottom 1rem
      a
        // font-family 'Bree Serif', serif !important
        // text-transform uppercase
        margin-bottom 1rem
        color $second
        display block
.artigo-btn
  background-color $second
  color $white
  display inline-block
  padding 0.5rem 1rem
  font-weight bold
  font-size 0.7rem
  border-radius 5px
  text-decoration none
  &:hover
    color $white

.margintop2rem
  margin-top 2rem

#galeria
  a
    figure
      figcaption
        @media screen and (max-width 600px)
          opacity 1
          position relative !important
    outline: none !important

#show
  &.slick-initialized
    margin-bottom 0rem !important
  .slick-slide
    border none
    // border 1px solid rgba(0,0,0,0.2)
    img
      width 100%
      margin 0 auto
  .slick-arrow
    z-index 9999
    &:before
      color $second
      font-size 2rem
      background $white
      border-radius: 5rem
      padding 0.6rem 0.3rem 0.3rem 0.3rem
  .slick-prev
    left -5px
  .slick-next
    right 13px
        
#miniatura
  &.slick-initialized
    margin-bottom 2rem !important
  .slick-slide
    border none
    // border 1px solid rgba(0,0,0,0.2)
    img
      width 100%
      margin 0 auto
  .slick-list
    background-color $second

.galeria-listagem
  .row
    min-height auto !important

.desktop
  display none
  @media screen and (min-width: 767px)
    display block
.mobile
  display none
  @media screen and (max-width: 767px)
    display block
.img-full
  width 100%

.pagination 
  .page-item 
    &.active
      .page-link
        background-color lighten($black, 80)
        border-color lighten($black, 80)
        color $black
    .page-link
      color $black
      font-weight bold

iframe
  .fr-draggable
    margin-top 1rem